export class ChannelInfo {
  public static createEmpty(): ChannelInfo {
    // entity of chat
    return new ChannelInfo();
  }
  constructor(
    public Id: string = '',
    public EventId: number = 0,
    public AppId: number = 0,
    public Type: string = '',
    public GUID: string = '',
    public Name: string = '',
    public Details: ChannelDetails = ChannelDetails.createEmpty(),
  ) { }
}


export class ChannelDetails {
  public static createEmpty(): ChannelDetails {
    // entity of chat
    return new ChannelDetails();
  }
  constructor(
    public Description: string = '',
    public ImageUrl: string = '',
    public EntityType: string = '',
    public EntityId: number = 0,
  ) { }
}

