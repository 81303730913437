import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56cf9f65"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chat-list-item" }
const _hoisted_2 = { class: "item-content" }
const _hoisted_3 = { class: "text-content" }
const _hoisted_4 = { class: "p-chat-description" }
const _hoisted_5 = { class: "menu" }
const _hoisted_6 = {
  key: 0,
  class: "chat-dropdown-menu"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("label", null, _toDisplayString(_ctx.localChat.Name), 1),
        _createElementVNode("p", _hoisted_4, _toDisplayString(_ctx.localChat.Details.Description), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("button", {
        class: "menu-button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args)))
      }, " ⋮ "),
      (_ctx.showMenu)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createElementVNode("ul", null, [
              _createElementVNode("li", {
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.editItem && _ctx.editItem(...args)))
              }, _toDisplayString(_ctx.$t("Button.Edit")), 1),
              _createElementVNode("li", {
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.deleteItem && _ctx.deleteItem(...args)))
              }, _toDisplayString(_ctx.$t("Button.Delete")), 1)
            ])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}