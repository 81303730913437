import ChatTab from '@/components/EventSetting/Chat/ChatTab.vue';
import EventDetail  from '@/components/EventSetting/EventDetail.vue';
import FeaturesAndIntegrations  from '@/components/EventSetting/FeaturesAndIntegrations/FeaturesAndIntegrations.vue';
import { TabViewModel } from '@/entities/TabViewModel';
import { t } from '@/main';

export class EventSettingTabList {
  public TabViewModels: TabViewModel[] = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor() {
    this.TabViewModels.push({ Id: 1, ComponentName: EventDetail, Header: t('EventSetting.EventInformation').toString() ,IsDisabled: false});
    this.TabViewModels.push({ Id: 2, ComponentName: FeaturesAndIntegrations, Header: t('EventSetting.FeaturesAndIntegrations').toString(), IsDisabled: false });
    this.TabViewModels.push({ Id: 5, ComponentName: ChatTab, Header: t('EventSetting.Chat').toString(), IsDisabled: false });
  }
}
