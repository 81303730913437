
import { Component, Prop, Vue } from 'vue-facing-decorator';
import VueModalPopUp from '@/components/Common/VueModalPopUp.vue';
import { ChannelInfo } from '@/entities/Chat/ChannelInfo';

@Component({
  components: {
    VueModalPopUp,
  },
})
class ChatListing extends Vue {
  // Props passed from parent component
  @Prop() chatData!: ChannelInfo;
  // Local chat data (initialized with empty data)
  private localChat: ChannelInfo = ChannelInfo.createEmpty();
  // State to toggle the dropdown menu visibility
  private showMenu = false;

  // Called when the component is mounted
  private mounted() {
    this.localChat = this.chatData;
  }

  // Method to toggle the dropdown menu
  private toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  // Method to emit the edit event
  private editItem() {
    this.$emit('edit', this.localChat.Id); // Make sure chatData has an 'id' field
  }

  // Method to emit the delete event
  private deleteItem() {
    this.$emit('delete', this.localChat.Id); // Make sure chatData has an 'id' field
  }
}

export default ChatListing;
